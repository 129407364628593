<template>
  <vue-tel-input
    :value="value"
    mode="international"
    defaultCountry="dk"
    :autoDefaultCountry="false"
    :validCharactersOnly="true"
    :preferredCountries="['dk']"
    :inputOptions="{ name, placeholder, required }"
    @input="v => $emit('input', v)"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Telefonnummer'
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: null
    }
  }
}
</script>