<template>
  <v-container>
    <v-toolbar class="mb-3">
      <v-toolbar-title>
        {{ $t('admin.personrole.addUser') }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        :loading="saving"
        @click="create"
      >
        <v-icon left> mdi-account-plus </v-icon>
        {{ $t('admin.personrole.create') }}
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-alert type="info" text>
          Alle felter skal udfyldes
        </v-alert>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.firstName"
                label="Fornavn"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.lastName"
                label="Efternavn"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="user.email"
            label="E-mail"
            :rules="[rules.required, rules.email]"
          />
          <v-row>
            <v-col cols="12" md="6">
              <tel-input-component
                v-model="user.phone"
                name="phone"
                placeholder="Telefonnummer"
                :required="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <tel-input-component
                v-model="user.cellPhone"
                name="phone"
                placeholder="Mobilnummer"
                :required="true"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.title"
                label="Titel"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="user.organizationId"
                :items="organizations"
                :rules="[rules.required]"
                label="Organisation"
                item-text="title"
                item-value="id"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import TelInputComponent from '@/components/TelInputComponent.vue';

export default {
  name: 'UserCreate',
  components: { TelInputComponent },
  data() {
    return {
      loading: false,
      saving: false,
      valid: false,
      user: {
        firstName: null,
        lastName: null,
        email: null,
        title: null,
        phone: null,
        cellPhone: null,
        organizationId: null,
      },
      organizations: [],
      rules: {
        required: value => !!value || 'Skal udfyldes',
        email: value => !!value?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) || 'Indtast en gyldig e-mail'
      }
    }
  },
  mounted() {
    this.loading = true;

    // Mock the data
    // this.firstName = 'Luke'
    // this.lastName = 'Skywalker'
    // this.email = 'luke@tatooine.com'
    // this.cellPhone = '12345678'
    // this.title = 'Jedi Master'

    this.$AuthService.api.get('organization/organizations').then(res => {
      this.organizations = res.data.model;
    })

    this.loading = false;
  },
  methods: {
    create: async function(navigateAfterCreation = true) {
      if (!this.user.phone || !this.user.cellPhone) {
        this.$notifier.showError({ message: 'Telefonnummer og mobilnummer skal udfyldes' })
        return
      }

      if (!this.$refs.form.validate()) return

      try {
        this.saving = true;
        
        const { data } = await this.$AuthService.api.post('user', this.user);

        if (data.errors.length || !data.success) throw data.errors[0] ?? data.message

        this.$notifier.showSuccess({ message: 'Brugeren blev oprettet' });

        this.$refs.form.reset()

        if (navigateAfterCreation) {
          this.$router.push('/admin/user-edit/' + data.model)
        }
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>